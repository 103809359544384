import { Message, Flex, Button } from '@aws-amplify/ui-react';
import './message.css';

export const CustomMessage = ({ theme, heading, body, confirmedCount, setConfirmedCount,
                                setCanceled }) => {
    return (
        <div id="blocker">
            <Message id="customMessage"
                     width={{ base: "95%", large: "50%" }}
                     variation="filled"
                     colorTheme={theme}
                     hasIcon={false}
                     heading={heading}>
                    {body}
                    <Flex direction="row"
                          justifyContent="flex-end"
                          alignItems="center">
                        { theme !== "error" ?
                             <Button variation="primary"
                                     colorTheme="success"
                                     onClick={() => { setConfirmedCount(confirmedCount + 1) }}>
                                Confirm
                            </Button>
                        :
                             null
                        }
                        <Button variation="primary"
                                colorTheme="error"
                                onClick={setCanceled}>
                            Cancel
                    </Button>
                    </Flex>
            </Message>
        </div>
    )
}
