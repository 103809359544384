import { Loader, Text, Flex } from '@aws-amplify/ui-react';
import './loader.css';

export const ProgressBar = ({ percentage, text }) => {
    return (
        <div>
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="1.5rem" >
                <Loader id="progressbar"
                    variation="linear"
                    percentage={percentage}
                    isDeterminate={true}
                    isPercentageTextHidden={true}
                    size="large" />
                <Text id="currentStatus"
                      variation="primary">Current status: {text}</Text>
            </Flex>
        </div>
    );
};
