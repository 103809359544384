import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/*const progressBar = document.getElementsByClassName("progress-bar")
setInterval(() => {
    const computedStyle = getComputedStyle(progressBar[0])
    const width = parseFloat(computedStyle.getPropertyValue("--width")) || 0
    progressBar.style.setProperty("--width", width + .1)
}, 5)*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
