import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import './App.css';
import { Main } from './own_components/main'

Amplify.configure(awsExports);

export default function App() {
    useEffect(() => {
        document.title = "Authentication"
    }, [])
    return (
        <Authenticator variation="modal"
                       hideSignUp={true}>
            {({ signOut, user }) => (
                <Main signOut={signOut}
                      user={user} />
            )}
        </Authenticator>
    );
}
