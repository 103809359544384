import { useState, useRef, useEffect, useCallback } from 'react';
import { Flex, Button, Card, DropZone, Text, VisuallyHidden, Link, ScrollView, Divider,
         SwitchField, useBreakpointValue } from '@aws-amplify/ui-react';
import { BsFileEarmarkPdf, BsPersonBadge, BsCpu, BsEscape, BsBrightnessHighFill, BsMoonFill } from "react-icons/bs";
import '@aws-amplify/ui-react/styles.css';
import axios from 'axios';
import { ProgressBar } from "./loader";
import { SelectorField } from "./selector";
import { CustomMessage } from "./message";
import packageJson from '../../package.json';
import './main.css';

const sleep = ms => new Promise(r => setTimeout(r, ms));

function ConstructAlertMessage(setMessageTheme, setMessageHeading, setMessage,
                               heading, message) {
    setMessageTheme("error");
    setMessageHeading(heading);
    setMessage(message);
}

async function SendRequest({url="", method="get", endpoint="", headers={}, data={}} = {},
                            setMessage, setMessageHeading, setShowMessage, setMessageTheme) {
    let request = {};
    request.method = method;
    request.baseURL = url === "" ? "https://rntmzhnaibvrt6xztigmo3rwiq0qvdjh.lambda-url.us-east-2.on.aws" : url;
    request.timeout = 60*1000;
    if (endpoint) {
        request.url = endpoint;
    }
    if (data) {
        request.data = data;
    }
    if (headers) {
        request.headers = headers;
    }
    let response = null;
    try {
        response = await axios(request);
    } catch(error) {
        if ("response" in error) {
            if (400 === error.response.status) {
                response = error.response;
            } else if (500 === error.response.status) {
                console.log(error.response.data.message);
            }
        } else {
            let heading = "Failed to process request"
            let message = "Got error while trying to process the request.";
            if ("ERR_NETWORK" === error.code) {
                heading = "Network connection error"
                message = "Please check your network connection."
            } else if ("ECONNABORTED" === error.code) {
                heading = "Bad connection detected";
                message = "Please use faster(>2Mbps) connection and try again.";
            }
            ConstructAlertMessage(setMessageTheme, setMessageHeading, setMessage, heading, message);
            setShowMessage(true);
        }
    }
    return response;
}

function ChangeVisibilityById(name, state) {
    document.getElementById(name).style.setProperty("display", state);
}

async function OpenInNewTab(jsonData, setMessage, setMessageHeading, setShowMessage,
                            setMessageTheme){
    let response = await SendRequest({ method: "post",
                                       endpoint: "/db_manager/prettify_json",
                                       data: {"json": jsonData},
                                       headers: {"Content-Type": "Application/json"} },
                                     setMessage, setMessageHeading, setShowMessage,
                                     setMessageTheme);
    if (null !== response) {
        var w = window.open("report", "_blank");
        w.document.write(response.data);
    }
}

function UpdateFacilityCodes(data, setFacilityCodes, setIsFacilityLoading, setIdObject) {
    let facilityCodes = [];
    setIdObject(data);
    Object.entries(data).map(([facilityCode, ]) => {
        facilityCodes.push({id: facilityCode, label: facilityCode});
        return null;
    })
    facilityCodes.sort((a, b) => a.id.localeCompare(b.id));
    setFacilityCodes(facilityCodes);
    setIsFacilityLoading(false);
}

async function GetFacilityCodes(setFacilityCodes, setIsFacilityLoading, setIdObject,
                                setMessage, setMessageHeading, setShowMessage, setMessageTheme) {
    setIsFacilityLoading(true);
    let response = await SendRequest({ method: "get",
                                       endpoint: "/db_manager/get_unique_ids",
                                       headers: {"Content-Type": "Application/json"} },
                                     setMessage, setMessageHeading, setShowMessage,
                                     setMessageTheme);
    if (null !== response) {
        UpdateFacilityCodes(response.data, setFacilityCodes, setIsFacilityLoading, setIdObject);
    }
}

async function UpdateState(text, time, stepInfo, setPercentage, setText) {
    const difference = stepInfo["current"] - stepInfo["previous"];
    const step = difference > 10 ? 100 : 1;
    const percentageStep =  difference / step;
    const sleepTime = time / step;
    while (stepInfo["previous"] < stepInfo["current"]) {
        stepInfo["previous"] += percentageStep;
        setPercentage(stepInfo["previous"]);
        setText(text+" ("+Math.ceil(stepInfo["previous"])+"%)");
        await sleep(sleepTime);
    }
}

function ConstructConfirmationMessage(setMessageTheme, setMessageHeading,
                                      setMessage, facilityCode, projectId, isFacilityNew,
                                      isProjectNew, files) {
    const filesLimit = 20;
    const filesCount = files.length;
    setMessageTheme("warning");
    setMessageHeading("Confirm selection");
    let message = "Please review your selections below:\n\n";
    message += "\tFacility Code:\t" + facilityCode;
    message += (isFacilityNew ? "" : " (NEW)");
    message += "\n";
    message += "\tProject ID:\t" + projectId;
    message += (isProjectNew ? "" : " (NEW)");
    message += "\n";
    message += "\tFiles:\n";
    for (let i = 0; i < Math.min(filesCount, filesLimit); i++){
        message += `\t\t${files[i].name}\n`;
    }
    if (filesCount > filesLimit) {
        message += "\t\tand " + (filesCount - filesLimit) + " files...\n";
    }
    message += "\n"
    message += "Press 'Confirm' to start ingestion, 'Cancel' to abort.\n\n";
    setMessage(message);
}

function CheckValidityOfFiles(files, setMessageTheme, setMessageHeading, setMessage){
    var invalidFiles = [];
    var duplicateFileNames = [];
    for (var j = 0; j < files.length; j++){
        for (var i = j + 1; i < files.length; i++) {
            if (files[j].name === files[i].name && !duplicateFileNames.includes(files[j].name)) {
                duplicateFileNames.push(files[j].name);
                break;
            }
        }
        if (files[j].type !== "application/pdf"){
            invalidFiles.push(files[j].name);
        }
    }
    let incorrectFileTypes = `"${invalidFiles.join("\",\"")}"`;
    let duplicateFileName = `"${duplicateFileNames.join("\",\"")}"`;
    if (incorrectFileTypes !== "\"\"") {
        let message = "Type of " + incorrectFileTypes + " file is invalid.\n\n";
        message += "Please select PDF files only!\n"
        ConstructAlertMessage(setMessageTheme, setMessageHeading, setMessage, "Incorrect file type",
                              message);
        return false;
    }
    if (duplicateFileName !== "\"\"") {
        let message = "The following file name(s) " + duplicateFileName + "detected multiple times.\n\n";
        message += "Please select files with unique names!\n"
        ConstructAlertMessage(setMessageTheme, setMessageHeading, setMessage, "Multiple same files detected",
                              message);
        return false;
    }
    return true;
}

async function ProcessChunk(files, facilityCode, projectId, setMessage, setMessageHeading,
                            setShowMessage, setMessageTheme) {
    const promises = files.map(async (file, ) => {
        const formData = new FormData();
        formData.append("file_name", file.name);
        formData.append("facility_code", facilityCode);
        formData.append("project_id", projectId);
        let response;
        response = await SendRequest({ method: "post",
                                       endpoint: "/db_manager/get_presigned_url",
                                       data: formData },
                                     setMessage, setMessageHeading, setShowMessage,
                                     setMessageTheme);
        if (null !== response) {
            if (200 === response.status) {
                response = await SendRequest({ url: response.data["url"],
                                               method: "put",
                                               data: file,
                                               headers: {"Content-Type": file.type} },
                                             setMessage, setMessageHeading, setShowMessage, setMessageTheme);
                if (null !== response && 200 === response.status) {
                    response = await SendRequest({ method: "post",
                                                   endpoint: "/db_manager/process_file",
                                                   data: formData,
                                                   headers: {"Content-Type": "multipart/form-data"} },
                                                 setMessage, setMessageHeading, setShowMessage,
                                                 setMessageTheme);
                }
            }
        } else {
            var heading = "Failed to process file(s)";
            var message = "Something went wrong.\n\nPlease try again later.";
            ConstructAlertMessage(setMessageTheme, setMessageHeading, setMessage, heading, message);
            setShowMessage(true);
        }
        return response;
    });
    return await Promise.all(promises);
}

async function ProcessFiles(files, facilityCode, projectId, setText, setPercentage, setJsonData,
                            setDetectedTags, setIngestedTags, setMessageTheme, setMessageHeading,
                            setMessage, setShowMessage, setDisableBrowse, setIsProcessing, setFiles,
                            setFacilityCodes, setIsFacilityLoading, setIdObject, setProjectId,
                            setProjectIdStatus) {
    let chunkSize = 40;
    var stepInfo = {"previous" : 0, "current" : 0};
    const chunks = files.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/chunkSize)
        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, [])
    let processSteps = chunks.length;
    var stepSize = Math.ceil(80 / processSteps);
    await UpdateState("Uploading", 10, stepInfo, setPercentage, setText);
    var results = [];
    for (let i = 0; i < chunks.length; i++) {
        stepInfo["current"] = stepInfo["current"] + stepSize;
        await UpdateState("Processing", 100, stepInfo, setPercentage, setText);
        let result = await ProcessChunk(chunks[i], facilityCode, projectId, setMessage,
                                        setMessageHeading, setShowMessage, setMessageTheme);
        results = results.concat(result);
    }
    setFiles([]);
    stepInfo["current"] = 90;
    await UpdateState("Retrieving results", 300, stepInfo, setPercentage, setText);
    var detectedTags = 0;
    var ingestedTags = 0;
    var jsonData = [];
    var failed_responses = ""
    for (let i = 0; i < results.length; i++) {
        let response = results[i];
        if (null === response) {
            continue;
        }
        let data = response.data;
        if (200 !== response.status) {
            failed_responses += "\t" + data["file_name"] + ": " + data["message"] + "\n";
            continue;
        }
        detectedTags += results[i].data["total"]["detected"];
        ingestedTags += results[i].data["total"]["ingested"];
        jsonData.push(results[i].data);
    }
    stepInfo["current"] = 100;
    await UpdateState("Done", 100, stepInfo, setPercentage, setText);
    setDisableBrowse(false);
    setIsProcessing(false);
    ChangeVisibilityById("progressbar", "none");
    ChangeVisibilityById("currentStatus", "none");
    if ("" !== failed_responses) {
        var heading = "Errors during processing";
        let message = "\nError details:\n\n" + failed_responses + "\nPlease try again."
        ConstructAlertMessage(setMessageTheme, setMessageHeading, setMessage, heading, message);
        setShowMessage(true);
    }
    if (jsonData.length) {
        setDetectedTags(detectedTags);
        setIngestedTags(ingestedTags);
        setJsonData(jsonData);
        ChangeVisibilityById("resultsCard", "block");
    }
    setProjectId("");
    setProjectIdStatus("Empty");
    GetFacilityCodes(setFacilityCodes, setIsFacilityLoading, setIdObject,
                     setMessage, setMessageHeading, setShowMessage, setMessageTheme);
}

function UploadFiles(files, facilityCode, projectId, setText, setPercentage, setJsonData,
                     setDetectedTags, setIngestedTags, setMessageTheme, setMessageHeading,
                     setMessage, setShowMessage, setDisableBrowse, setIsProcessing, setFiles,
                     setFacilityCodes, setIsFacilityLoading, setIdObject, setProjectId,
                     setProjectIdStatus) {
    ChangeVisibilityById("progressbar", "block");
    ChangeVisibilityById("currentStatus", "block");
    setIsProcessing(true);
    ProcessFiles(files, facilityCode, projectId, setText, setPercentage, setJsonData,
                 setDetectedTags, setIngestedTags, setMessageTheme, setMessageHeading,
                 setMessage, setShowMessage, setDisableBrowse, setIsProcessing, setFiles,
                 setFacilityCodes, setIsFacilityLoading, setIdObject, setProjectId,
                 setProjectIdStatus);
}

function onFilePickerChange(event, setMessageTheme, setMessageHeading,
                            setMessage, setShowMessage, setFiles, setFilesCount) {
    const { files } = event.target;
    if (!files || files.length === 0) {
        return;
    }
    if (! CheckValidityOfFiles(files, setMessageTheme, setMessageHeading,
                               setMessage)) {
        setShowMessage(true);
        return;
    }
    setFiles(Array.from(files));
    setFilesCount(files.length);
    ChangeVisibilityById("resultsCard", "none");
    event.target.value = "";
}

function ProcessButtonOnClick(files, idObject, projectIds, setMessageTheme,
                              setMessageHeading, setMessage, setShowMessage) {
    let facilityCodeSelector = document.getElementById("facilityCodeSelector");
    let projectIdSelector = document.getElementById("projectIdSelector");
    let facilityCode = facilityCodeSelector.value;
    let projectId = projectIdSelector.value;
    let isFoundId = false;
    for (let i in projectIds) {
        if (projectId === projectIds[i].id) {
            isFoundId = true;
            break;
        }
    }
    ConstructConfirmationMessage(setMessageTheme, setMessageHeading, setMessage,
                                 facilityCode, projectId, facilityCode in idObject, isFoundId,
                                 files);
    setShowMessage(true);
}

function ChangeTheme(isChecked) {
    if (isChecked) {
        document.body.classList.add('dark-mode')
    } else {
        document.body.classList.remove('dark-mode')
    }
}

function NavBar({ user, signOut }) {
    const title="DB Manager"
    useEffect(() => {
        document.title = title;
    }, []);
    return (
        <Flex id="navBar"
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              wrap="nowrap">
            <Flex direction="column"
                  alignItems="center"
                  alignContent="center"
                  gap="0.1rem">
                <Text><BsPersonBadge />Welcome {user.username}</Text>
                <Flex direction="row"
                      alignItems="center"
                      alignContent="center"
                      gap="0.2rem">
                    <BsBrightnessHighFill />
                    <SwitchField isDisabled={false}
                                 isLabelHidden={true}
                                 onChange={(event) => ChangeTheme(event.target.checked)}></SwitchField>
                    <BsMoonFill />
                </Flex>
            </Flex>
            <Flex direction="column"
                  alignItems="center"
                  alignContent="center"
                  gap="0.1rem">
                <Text fontSize="large">{title}</Text>
                <Text fontSize="small">Version: {packageJson.version}</Text>
            </Flex>
            <Button id="signOutButton"
                    onClick={signOut}
                    variation="primary"
                    colorTheme="error"
                    gap="0.2rem">
                <BsEscape /> Sign out
            </Button>
        </Flex>
    )
}


function FileSelector({ setFiles, setFilesCount, disableBrowse, setMessageTheme, setMessageHeading,
                        setMessage, setShowMessage, acceptedFileTypes }) {
    const hiddenInput = useRef(null);
    return (
        <div id="fileSelector">
            <Button id="browseButton"
                    size="small"
                    isDisabled={disableBrowse}
                    variation="primary"
                    onClick={() => hiddenInput.current.click()}
                    gap="0.2rem">
                <BsFileEarmarkPdf /> Browse
            </Button>
            <VisuallyHidden>
                <input type="file"
                       tabIndex={-1}
                       multiple={true}
                       ref={hiddenInput}
                       onChange={(event) => onFilePickerChange(event,
                                                               setMessageTheme,
                                                               setMessageHeading,
                                                               setMessage,
                                                               setShowMessage,
                                                               setFiles,
                                                               setFilesCount) }
                    accept={acceptedFileTypes.join(',')} />
            </VisuallyHidden>
        </div>
    );
}

function InputField({ setFiles, setFilesCount, disableBrowse, setMessageTheme, setMessageHeading,
                      setMessage, setShowMessage }) {
    const acceptedFileTypes = ['application/pdf'];
    const isMobile = useBreakpointValue({
        base: true,
        large: false
    })
    return (
        <div>
            { isMobile ?
                <FileSelector setFiles={setFiles}
                              setFilesCount={setFilesCount}
                              disableBrowse={disableBrowse}
                              setMessageTheme={setMessageTheme}
                              setMessageHeading={setMessageHeading}
                              setMessage={setMessage}
                              setShowMessage={setShowMessage}
                              acceptedFileTypes={acceptedFileTypes} />
            :
                <DropZone id="dropZone"
                          acceptedFileTypes={acceptedFileTypes}
                          onDropComplete={({ acceptedFiles }) => { setFiles(acceptedFiles);
                                                                   setFilesCount(acceptedFiles.length); }}>
                    <Flex direction="column"
                          alignItems="center">
                        <Text>Drop drawing(s) here or</Text>
                        <FileSelector setFiles={setFiles}
                                      setFilesCount={setFilesCount}
                                      disableBrowse={disableBrowse}
                                      setMessageTheme={setMessageTheme}
                                      setMessageHeading={setMessageHeading}
                                      setMessage={setMessage}
                                      setShowMessage={setShowMessage}
                                      acceptedFileTypes={acceptedFileTypes} />
                    </Flex>
                </DropZone>
            }
        </div>
    );
}

function UploadCard({ files, setFilesCount, setText, setPercentage, setFiles, setJsonData,
                      setDetectedTags, setIngestedTags, message,  setMessage, messageHeading,
                      setMessageHeading, showMessage, setShowMessage, messageTheme,
                      setMessageTheme }) {
    const [disableProcess, setDisableProcess] = useState(true);
    const [disableBrowse, setDisableBrowse] = useState(false);
    const [idObject, setIdObject] = useState({});
    const [facilityCode, setFacilityCode] = useState("");
    const [projectId, setProjectId] = useState("");
    const [facilityCodes, setFacilityCodes] = useState([]);
    const [projectIds, setProjectIds] = useState([]);
    const [isFacilityLoading, setIsFacilityLoading] = useState(true);
    const [projectIdStatus, setProjectIdStatus] = useState("Not set");
    const [facilityCodeStatus, setFacilityCodeStatus] = useState("Not set");
    const [confirmedCount, setConfirmedCount] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    useEffect(() => {
        GetFacilityCodes(setFacilityCodes, setIsFacilityLoading, setIdObject,
                         setMessage, setMessageHeading, setShowMessage, setMessageTheme);
    }, []);
    useEffect(() => {
        let projectIds = [];
        if (facilityCode in idObject) {
            let ids = idObject[facilityCode];
            for (let i in ids) {
                let id = ids[i];
                projectIds.push({id: id, label: id});
            }
        }
        projectIds.sort((a, b) => a.id.localeCompare(b.id));
        setProjectIds(projectIds);
    }, [facilityCode, idObject])
    useEffect(() => {
        let allowed_states = ["New", "Exists"];
        setDisableProcess(files.length === 0
                          || ! allowed_states.includes(facilityCodeStatus)
                          || ! allowed_states.includes(projectIdStatus));
    }, [files, facilityCodeStatus, projectIdStatus]);
    useEffect(() => {
        if (confirmedCount) {
            setShowMessage(false);
            setDisableBrowse(true);
            setDisableProcess(true);
            UploadFiles(files, facilityCode, projectId, setText, setPercentage, setJsonData,
                        setDetectedTags, setIngestedTags, setMessageTheme, setMessageHeading,
                        setMessage, setShowMessage, setDisableBrowse, setIsProcessing, setFiles,
                        setFacilityCodes, setIsFacilityLoading, setIdObject, setProjectId,
                        setProjectIdStatus);
        }
    }, [confirmedCount])
    useEffect(() => {
        ChangeVisibilityById("filesView", files.length ? "block" : "none");
    }, [files]);
    const setCanceled = useCallback(() => {
            setShowMessage(false);
    }, [])
    return (
        <Card variation="default"
              width={{ base: "90%", large: "22%" }}>
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="1rem">
                <SelectorField facilityCode={facilityCode}
                               facilityCodes={facilityCodes}
                               isFacilityLoading={isFacilityLoading}
                               setFacilityCode={setFacilityCode}
                               facilityCodeStatus={facilityCodeStatus}
                               setFacilityCodeStatus={setFacilityCodeStatus}
                               projectId={projectId}
                               projectIds={projectIds}
                               setProjectId={setProjectId}
                               projectIdStatus={projectIdStatus}
                               setProjectIdStatus={setProjectIdStatus}
                               isProcessing={isProcessing}
                               idObject={idObject} />
                <InputField setFiles={setFiles}
                            setFilesCount={setFilesCount}
                            disableBrowse={disableBrowse}
                            setMessageTheme={setMessageTheme}
                            setMessageHeading={setMessageHeading}
                            setMessage={setMessage}
                            setShowMessage={setShowMessage} />
                <ScrollView id="filesView">
                    {files.map((file) => (<Text key={file.name}>{file.name}</Text>))}
                </ScrollView>
                { showMessage ?
                    <CustomMessage theme={messageTheme}
                                   heading={messageHeading}
                                   body={message}
                                   confirmedCount={confirmedCount}
                                   setConfirmedCount={setConfirmedCount}
                                   setCanceled={setCanceled} />
                :
                    null
                }
                <Button id="processButton"
                        onClick={() => { ProcessButtonOnClick(files, idObject, projectIds,
                                                              setMessageTheme,
                                                              setMessageHeading,
                                                              setMessage,
                                                              setShowMessage); }}
                        isDisabled={disableProcess}
                        variation="primary"
                        colorTheme="success"
                        gap="0.2rem">
                    <BsCpu /> Process
                </Button>
            </Flex>
        </Card>
    );
}

function Results({ filesCount,  jsonData, detectedTags, ingestedTags, setMessage,
                   setMessageHeading, setShowMessage, setMessageTheme }) {
    var processColor = "#AA0000";
    var processStatus = "FAILURE";
    if (ingestedTags === detectedTags && ingestedTags !== 0){
        processStatus = "SUCCESS";
        processColor = "#007700";
    }
    const styles = { successColor: { color: processColor } }
    return (
        <Card variation="elevated"
              id="resultsCard"
              width={{ base: "90%", large: "22%" }}>
            <Text id="resultsHeader" as="p">Results:</Text>
            <Divider orientation="horizontal"
                     size="small"/>
            <Text>Success status: <span style={styles.successColor}>{processStatus}</span></Text>
            <Text>Number of processed Architectural drawings: {filesCount}</Text>
            <Divider orientation="horizontal"
                     size="small" />
            <Text>Number of detected TAGs(Devices) in files: {detectedTags} </Text>
            <Text>Number of ingested TAGs(Devices) in DB: {ingestedTags}</Text>
            <Divider orientation="horizontal"
                     size="small" />
            <Text>View detailed report via this <Link id="reportLink"
                                                      onClick={() => OpenInNewTab(jsonData,
                                                                                  setMessage,
                                                                                  setMessageHeading,
                                                                                  setShowMessage,
                                                                                  setMessageTheme)}
                                                      isExternal={true} >link</Link>.</Text>
        </Card>
    );
}

export const Main = ({ signOut, user }) => {
    const [percentage, setPercentage] = useState(0);
    const [text, setText] = useState("");
    const [files, setFiles] = useState([]);
    const [jsonData, setJsonData] = useState(null);
    const [detectedTags, setDetectedTags] = useState(0);
    const [ingestedTags, setIngestedTags] = useState(0);
    const [filesCount, setFilesCount] = useState(0);
    const [message, setMessage] = useState("");
    const [messageHeading, setMessageHeading] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [messageTheme, setMessageTheme] = useState("neutral");
    return (
        <main>
            <Flex direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                  gap="1.5rem">
                <NavBar signOut={signOut}
                        user={user} />
                <UploadCard files={files}
                            setFilesCount={setFilesCount}
                            setText={setText}
                            setPercentage={setPercentage}
                            setFiles={setFiles}
                            setJsonData={setJsonData}
                            setDetectedTags={setDetectedTags}
                            setIngestedTags={setIngestedTags}
                            message={message}
                            setMessage={setMessage}
                            messageHeading={messageHeading}
                            setMessageHeading={setMessageHeading}
                            showMessage={showMessage}
                            setShowMessage={setShowMessage}
                            messageTheme={messageTheme}
                            setMessageTheme={setMessageTheme} />
                <ProgressBar percentage={percentage}
                             text={text} />
                <Results filesCount={filesCount}
                         jsonData={jsonData}
                         detectedTags={detectedTags}
                         ingestedTags={ingestedTags}
                         setMessage={setMessage}
                         setMessageHeading={setMessageHeading}
                         setShowMessage={setShowMessage}
                         setMessageTheme={setMessageTheme} />
            </Flex>
        </main>
    );
}
